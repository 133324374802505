<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1100px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$t('parts.labelWidth')"
        :model="parts"
        :rules="ruleValidate"
        style="padding-right: 20px">
        <div class="vm-separate">
          <el-form-item :label="$t('parts.no')" prop="no">
            <el-input v-model="parts.no"></el-input>
          </el-form-item>
          <el-form-item :label="$t('parts.name')" prop="name">
            <el-input v-model="parts.name"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('parts.partsTypeName')" prop="elevatorNo">
            <el-input v-model="parts.partsTypeName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectPartsType.open()">
              </el-button>
            </el-input>
            <parts-type-select ref="selectPartsType" @select="onSelectPartsType"></parts-type-select>
          </el-form-item>
          <el-form-item :label="$t('parts.model')">
            <el-input v-model="parts.model"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('parts.elevatorCompanyName')" prop="elevatorCompanyName">
            <el-input v-model="parts.elevatorCompanyName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$refs.selectCompany.open(1)">
              </el-button>
            </el-input>
            <company-select ref="selectCompany" @select="onSelectCompany"></company-select>
          </el-form-item>
          <el-form-item :label="$t('parts.partsSn')" prop="partsSn">
            <el-input
              v-model="parts.partsSn"
              :placeholder="$t('common.pleasePartsSn')"
              minlength="34"
              maxlength="34"></el-input>
          </el-form-item>
          <!--          <el-form-item :label="$t('parts.status')" prop="status">-->
          <!--            <el-radio-group v-model="parts.status">-->
          <!--              <el-radio class="radio" :label="0">{{$t('parts.result.noBand')}}</el-radio>-->
          <!--              <el-radio class="radio" :label="1">{{$t('parts.result.band')}}</el-radio>-->
          <!--            </el-radio-group>-->
          <!--          </el-form-item>-->
        </div>
        <el-form-item :label="$t('parts.elevatorProductDescription')" prop="note">
          <el-input
            v-model="parts.elevatorProductDescription"
            :placeholder="$t('common.pleaseEnter')"
            type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import PartsTypeSelect from "../partsType/PartsTypeSelect";
  import CompanySelect from "../company/CompanySelect";


  export default {
    components: {CompanySelect, PartsTypeSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        inNetState: 1,
        parts: {
          id: 0,
          no: "",
          name: "",
          partsSn: "",
          partsTypeId: 0,
          partsTypeName: "",
          elevatorCompanyName: "",
          elevatorCompanyId: "",
          model: "",
          brand: 0,
          price: "",
          times: "",
          life: "",
          status: 0,
          hot: 0,
          isDeleted: 0,
          elevatorProductDescription: "",
          createTime: "",
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("parts.tip.name"), trigger: "change"},
          ],
          no: [
            {required: true, message: this.$t("parts.tip.no"), trigger: "change"},
          ],
          partsSn: [
            {required: true, pattern: /^[0-9a-zA-Z]{34}$/, message: this.$t("parts.tip.partsSn"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.parts.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.parts");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.parts.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById("parts", this.parts.id).then(res => {
          this.parts = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
        this.$refs.formValidate.resetFields();
      },
      onSelectPartsType(row) {
        this.parts.partsTypeId = row.id;
        this.parts.partsTypeName = row.name;
      },
      onSelectCompany(row) {
        this.parts.elevatorCompanyId = row.id;
        this.parts.elevatorCompanyName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save("parts", this.parts).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.parts.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
