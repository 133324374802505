<template>
  <el-dialog
    :title="$t('parts.bindingRecord')"
    :visible.sync="dialogTableVisible"
    width="1100px"
    top="5vh">
    <el-table :data="elevatorPartsList">
      <el-table-column property="partsSn" :label="$t('parts.partsSn')" width="280"></el-table-column>
      <el-table-column property="elevatorName" :label="$t('elevator.name')" width="180">
        <template slot-scope="scope">
          <router-link :to="'/elevator/'+ scope.row.elevatorID" target="_blank">{{scope.row.elevatorName}}</router-link>
        </template>
      </el-table-column>
      <el-table-column property="elevatorRegisterNo" :label="$t('elevator.registerCode')" width="110"></el-table-column>
      <el-table-column property="floor" :label="$t('elevator.elevatorParts.floor')" width="60"></el-table-column>
      <el-table-column property="activateTime" :label="$t('elevator.elevatorParts.activateTime')" width="120"></el-table-column>
      <el-table-column property="createTime" :label="$t('elevator.elevatorParts.createTime')" width="120"></el-table-column>
      <el-table-column property="activator" :label="$t('elevator.elevatorParts.activator')"></el-table-column>
      <el-table-column property="status" :label="$t('parts.status')">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 20">
            {{$t("elevator.elevatorParts.remove")}}
          </span>
          <span v-else-if="scope.row.status === 10" type="success">
            {{$t("elevator.elevatorParts.activated")}}
          </span>
<!--          <span v-else>-->
<!--            {{$t("elevator.elevatorParts.notActivated", "未认证")}}-->
<!--          </span>-->
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogTableVisible: false,
        parts: {
          id: 0,
        },
        elevatorPartsList: [{
          elevatorID: 0,
          elevatorRegisterNo: "",
          elevatorName: "",
          partsSn: "",
          activateTime: "",
          createTime: "",
          activator: "",
          floor: "",
        }],
      };
    },
    methods: {
      open(id) {
        this.parts.id = id;
        this.dialogTableVisible = true;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$api.getById("elevatorParts/parts", this.parts.id).then(res => {
          this.elevatorPartsList = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
