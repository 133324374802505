<template>
  <div style="display: flex;">
    <div style="margin-right: 16px;">
      <!-- <el-button v-if="editAuth" type="primary" @click="$refs.editPagePartsType.open(0, null, null, 1, null, null)">
        新建一级
      </el-button> -->
      <div v-loading="loading" style="height: 91vh;overflow: auto;border-radius: 12px;">
        <div>
          <!-- <el-input v-model="filterText" style="width: 100%;" placeholder="输入关键字进行过滤"></el-input> -->
          <el-tree
            ref="tree"
            style="background-color: #EBEBEB;font-size: 14px;"
            :data="data"
            :props="defaultProps"
            :filter-node-method="filterNode"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false">
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <span @click="tree(data)">{{ node.label }}</span>
              <span>
                <el-button v-if="editAuth && data.id > 0" type="text" @click="append(data)">
                  {{$t("part.addChild")}}
                </el-button>
                <el-button v-if="editAuth && data.id > 0" type="text" @click="edit(node, data)">
                  {{$t("common.edit")}}
                </el-button>
                <el-button v-if="editAuth && data.id > 0" type="text" @click="remove(data)">
                  {{$t("common.delete")}}
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <parts-type-edit ref="editPagePartsType" @save-success="getData()"></parts-type-edit>
    </div>
    <el-main style="padding:0;">
      <vm-table
        ref="vmTable"
        v-loading="loading"
        :filter.sync="search"
        url="parts"
        @clear="clearDateRange">
        <template slot="adSearch">
          <vm-search :label="$t('maintWorkOrder.filter')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('elevator.elevatorParts.activateTime')">
            <el-date-picker
              v-model="dateRange"
              value-format="yyyy-MM-dd"
              type="daterange"
              :range-separator="$t('common.to')"
              :start-placeholder="$t('common.startDate')"
              :end-placeholder="$t('common.endDate')"
              @change="handleDateChange">
            </el-date-picker>
          </vm-search>
          <vm-search :label="$t('elevator.elevatorParts.status')">
            <el-select v-model="search.status" :placeholder="$t('common.pleaseEnter')" clearable style="width: 100%">
              <el-option
                v-for="item in elevatorPartStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
        </template>
        <template slot="toolbar">
          <el-button class="addBtn" @click="$refs.editPage.open(0)">
            {{ $t("common.add") }}
          </el-button>
          <el-button :loading="exportLoading" @click="exportExcel">
            {{ $l("part.exportAuthenticated") }}
          </el-button>
          <el-button :loading="listExportLoading" @click="exportList">
            {{ $l("part.export") }}
          </el-button>
        </template>
        <el-table-column prop="no" :label="$t('parts.no')" width="120" align="center"></el-table-column>
        <el-table-column prop="name" :label="$t('parts.name')" width="100" align="center"></el-table-column>
        <el-table-column
          prop="partsTypeName"
          :label="$t('parts.partsTypeName')"
          width="100"
          align="center"></el-table-column>
        <el-table-column prop="partsSn" :label="$t('parts.partsSn')" width="280" align="center"></el-table-column>
        <el-table-column prop="status" :label="$t('parts.status')" width="100" align="center">
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.status === 1 && scope.row.elevatorId > 0"
              :to="'/elevator/' + scope.row.elevatorId"
              target="_blank">
              <el-tag type="success">{{ $t("parts.result.band") }}</el-tag>
            </router-link>
            <el-tag v-else type="info">
              {{ $t("parts.result.noBand") }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="elevatorCompanyName"
          :label="$t('parts.elevatorCompanyName')"
          align="center"></el-table-column>
        <el-table-column
          prop="createTime"
          width="140"
          :label="$t('msgPushRecord.createTime')"
          align="center"></el-table-column>
        <el-table-column
          prop="activateTime"
          :label="$t('elevator.elevatorParts.activateTime')"
          width="140"
          align="center"></el-table-column>
        <el-table-column
          prop="elevatorPartStatus"
          :label="$t('elevator.elevatorParts.status')"
          width="100"
          align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.elevatorPartStatus === 20" type="danger">
              {{ $t("elevator.elevatorParts.remove") }}
            </el-tag>
            <el-tag v-else-if="scope.row.elevatorPartStatus === 10" type="success">
              {{ $t("elevator.elevatorParts.activated") }}
            </el-tag>
            <!--            <el-tag v-else type="danger">-->
            <!--              {{$l("elevator.elevatorParts.notActivated", "未认证")}}-->
            <!--            </el-tag>-->
          </template>
        </el-table-column>
        <el-table-column
          v-if="editAuth"
          :label="$t('common.operation')"
          width="120"
          align="center"
          fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="getElevatorParts(scope.row)">
              {{ $t("elevator.elevatorParts.replacementRecord") }}
            </el-button>
            <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
            <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
              {{ $t("common.delete")
              }}
            </el-button>
          </template>
        </el-table-column>
      </vm-table>
      <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
      <part-bind-records-dialog ref="recordSelect"></part-bind-records-dialog>
    </el-main>
  </div>
</template>
<script>
  import EditDialog from "./PartsEdit.vue";
  import PartBindRecordsDialog from "./PartBindRecords";
  import PartsTypeEdit from "../partsType/PartsTypeEdit.vue";

  export default {
    components: { EditDialog, PartsTypeEdit, PartBindRecordsDialog },
    data() {
      return {
        filterText: "",
        dateRange: [],
        search: {
          filter: "",
          startTime: "",
          endTime: "",
          status: "",
          partsTypeId: 0,
        },
        elevatorParts: {
          id: 0,
          elevatorID: 0,
          partsID: 0,
          activateTime: "",
          createTime: "",
          activatorID: 0,
          elevatorPartStatus: "",
        },
        elevatorPartStatus: [
          { value: 0, label: this.$t("elevator.notActivated") },
          { value: 10, label: this.$t("elevator.activated") },
          { value: 20, label: this.$t("elevator.elevatorParts.remove") },
          { value: "", label: this.$t("elevator.all") },
        ],
        elevatorPartsSvo: {
          leaveNo: "",
          activateTime: [],
          status: "",
        },
        data: [
          {
            id: 0,
            name: this.$t("elevator.all"),
            parentID: -1,
            children: [],
          },
        ],
        defaultProps: {
          children: "children",
          label: "name",
        },
        dialogTableVisible: false,
        listExportLoading: false,
        editAuth: this.$auth(807),
        loading: false,
        exportLoading: false,
        parts: {
          id: 0,
          partsTypeId: 0,
        },
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    mounted() {
      this.getList(1);
      this.getData();
    },
    methods: {
      getList(pageIndex) {
        this.search.partsTypeId = this.parts.partsTypeId;
        this.$refs.vmTable.getList(pageIndex);
      },
      clearDateRange() {
        this.dateRange = [];
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      getElevatorParts(row) {
        this.$refs.recordSelect.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("parts.no") + " " + row.no + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("parts", row.id).then(res => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      exportList() {
        this.listExportLoading = true;
        let params = {
          leaveNo: this.search.filter,
          startTime: this.dateRange != null ? this.dateRange[0] : "",
          endTime: this.dateRange != null ? this.dateRange[1] : "",
          status: this.search.status,
        };
        this.$api
          .exportFile("parts/export", params, 60)
          .then(res => {
            let blob = new Blob([res.data]);
            let fileName = "PartsInfo.xlsx";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
            this.listExportLoading = false;
          })
          .catch(() => {
            this.listExportLoading = false;
          });
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      exportExcel() {
        this.exportLoading = true;
        let params = {
          leaveNo: this.search.filter,
          startTime: this.dateRange != null ? this.dateRange[0] : "",
          endTime: this.dateRange != null ? this.dateRange[1] : "",
          status: this.search.status,
        };
        this.$api
          .exportFile("elevatorParts/export", params, 60)
          .then(res => {
            let blob = new Blob([res.data]);
            let fileName = "EID.xlsx";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
            this.exportLoading = false;
          })
          .catch(() => {
            this.exportLoading = false;
          });
      },
      getData() {
        this.loading = true;
        this.$axios
          .get("partsTypes/tree").then((res) => {
            if (res) {
              this.data[0].children = JSON.parse(JSON.stringify(res.data));
              this.loading = false;
            } else {
              this.loading = false;
              this.$message.error(this.$t("common.tip.getDataError"));
            }
          });
      },
      tree(data) {
        this.parts.partsTypeId = data.id;
        this.getList(1);
      },
      filterNode(value, data, node) {
        //console.log(data);
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return node.label.indexOf(value) !== -1;
      },
      append(data) {
        this.$refs.editPagePartsType.open(data.id, null, null, 0, null, null, null);
      },
      edit(node, data) {
        this.$refs.editPagePartsType.open(node.parent.data.id, data.id, data.name, 0, data.no, data.partsTypeSn, data.desc);
      },
      remove(data) {
        this.$confirm(this.$t("common.delete"), this.$t("common.tips"), { type: "warning" }).then(() => {
          this.$api.deleteById("partsTypes", data.id).then(() => {
            this.getData();
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
